<script>
import AdminLayout from "@layouts/admin";
import DataTable from "@components/data-table";
import ModalComponent from "@components/modal";
import ManageRecharge from "./components/manage-recharge";
import { debounce } from "lodash";

export default {
    name: "ManageNoc",
    components: {
        AdminLayout,
        DataTable,
        ModalComponent,
        ManageRecharge,
    },
    data() {
        return {
            items: [],
            count: 0,
            headers: [
                { value: "name", text: "Name", width: "165", align: "left" },
                {
                    value: "shopName",
                    text: "Business Name",
                    width: "165",
                    align: "left",
                },
                { value: "phone", text: "Phone", width: "110", align: "left" },
                { value: "email", text: "Email", width: "210", align: "left" },
                {
                    value: "panNumber",
                    text: "PAN Number",
                    width: "120",
                    align: "center",
                },
                {
                    value: "isApproved",
                    text: "Is Approved",
                    width: "110",
                    align: "center",
                },
                {
                    value: "state",
                    text: "State",
                    width: "148",
                    align: "center",
                },
            ],
            limit: 0,
            current: 1,
            isModalVisible: false,
            selectedDealerId: null,
            showRechargeModal: false,
        };
    },
    methods: {
        async getdata(search = null) {
            try {
                const query = {
                    limit: this.limit,
                    offset: (this.current - 1) * this.limit,
                };

                if (search) {
                    query.search = search;
                }

                const { count, rows } = await this.$http.get(
                    "dealer/find-and-count-dealers",
                    query
                );

                this.items = rows;
                this.items = this.items.map((a) => ({
                    ...a,
                    shopName: a?.Dealer?.shopName,
                    panNumber: a?.Dealer?.panNumber,
                    state: a?.Dealer?.state,
                    dealerId: a.Dealer.id,
                    isApproved: a.Dealer?.isApproved
                        ? "<div class='text-green-700'>Yes</div>"
                        : "<div class='text-red-600'>No</div",
                }));
                this.count = count;
            } catch (err) {
                console.log(err);
                this.$utility.showErrorMessage(err.message);
            }
        },
        async handleClick(e) {
            try {
                const response = await this.$http.updateById(
                    "dealer/approve-dealer",
                    e.dealerId
                );
                this.$utility.showSuccessMessage(response.message);
                this.getdata();
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        handleFetch(obj) {
            this.current = obj.current;
            this.limit = obj.limit;
            this.getdata();
        },
        debounceSearch: debounce(function (text) {
            this.getdata(text);
        }, 600),
        handleRowClick(row, column, e) {
            this.$utility.goToPage("dealer.detail", {
                dealerId: row.Dealer.id,
            });
        },
        handleModal(row) {
            console.log({ row });
            this.selectedDealerId = row.Dealer.id;
            this.showRechargeModal = true;
        },
        async addDealerBalance(data) {
            try {
                await this.$http.rawPost(`dealer/add-balance/${this.selectedDealerId}`, data);
                this.showRechargeModal = false;

                this.$utility.showSuccessMessage("Balance successfully added!");
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
};
</script>
<template lang="pug">
admin-layout
    data-table(
        :showSearch="true",
        :count="count",
        @fetch="handleFetch",
        @search="debounceSearch"
    )
        el-table(:data="items", style="width: 100%", size="medium")
            el-table-column(
                v-for="col in headers",
                :key="col.id",
                :label="col.text",
                :width="col.width",
                :align="col.align"
            )
                template(#default="{row}")
                    span.cursor-pointer(
                        v-if="row[col.value]",
                        v-html="row[col.value]"
                    )
                    span(v-else) NA
            el-table-column(label="Actions", align="center")
                template(#default="{row}")
                    .flex.items-center.content-center
                        .ml-6(
                            @click="$utility.goToPage('dealer.detail', { dealerId: row.Dealer.id })"
                        )
                            el-button(
                                type="success",
                                size="small",
                                circle=""
                            ) <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" height="14px" color="white"><path fill="currentColor" d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352m0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448m0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160"></path></svg>
                        .ml-6
                            el-popconfirm(
                                confirmButtonText="Yes",
                                cancelButtonText="No, Thanks",
                                icon="el-icon-info",
                                iconColor="lightblue",
                                title="Are you sure to approve?",
                                @confirm="handleClick(row)"
                            )
                                template(#reference)
                                    el-button(
                                        type="primary",
                                        size="small",
                                        circle=""
                                    ) <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" height="14px" color="white" ><path fill="currentColor" d="M832 512a32 32 0 1 1 64 0v352a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32h352a32 32 0 0 1 0 64H192v640h640z"></path><path fill="currentColor" d="m469.952 554.24 52.8-7.552L847.104 222.4a32 32 0 1 0-45.248-45.248L477.44 501.44l-7.552 52.8zm422.4-422.4a96 96 0 0 1 0 135.808l-331.84 331.84a32 32 0 0 1-18.112 9.088L436.8 623.68a32 32 0 0 1-36.224-36.224l15.104-105.6a32 32 0 0 1 9.024-18.112l331.904-331.84a96 96 0 0 1 135.744 0z"></path></svg>
                        .ml-6(@click="handleModal(row)")
                            el-button(
                                type="warning",
                                size="small",
                                circle=""
                            ) <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" height="14px" color="white"><path fill="currentColor" d="M640 288h-64V128H128v704h384v32a32 32 0 0 0 32 32H96a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32h512a32 32 0 0 1 32 32z"></path><path fill="currentColor" d="M128 320v512h768V320zm-32-64h832a32 32 0 0 1 32 32v576a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V288a32 32 0 0 1 32-32"></path><path fill="currentColor" d="M704 640a64 64 0 1 1 0-128 64 64 0 0 1 0 128"></path></svg>
    modal-component(
        :visibility="showRechargeModal",
        @close="showRechargeModal = false",
        title="Add Dealer Recharge",
        width="55",
        :destroyOnClose="true"
    )
        manage-recharge(@addBalance="addDealerBalance")
</template>
